import BaseInvoker from './BaseInvoker';
import { Component } from 'react';

const urlInvoke = `/api`;
const urlInvokeMobility = `/api/mobility`;
const baseInvoker = new BaseInvoker();

export default class AppointmentApiInvoker extends Component {

	static getScheduleAppointment(appointmentId, callback, callerror) {
		const url = `${urlInvoke}/schedule/appointment/${appointmentId}`;
		baseInvoker.get(url, callback, callerror);
	}

	static getAppointmentsMobility(params, callback, callerror) {
		const url = `${urlInvokeMobility}/transfers/?${params}`;
		baseInvoker.get(url, callback, callerror);
	  }

	static getAppointments(callback, callerror) {
		const url = `${urlInvoke}/appointments`;
		baseInvoker.get(url, callback, callerror);
	}

	static getAppointmentById(appointmentId, callback, callerror) {
		const url = `${urlInvokeMobility}/transfer/${appointmentId}`;
		baseInvoker.get(url, callback, callerror);
	  }

	static getAppointmentsFilter(filter, callback, callerror) {
		const url = `${urlInvoke}/appointments-filter/?${filter}`;
		baseInvoker.get(url, callback, callerror);
	}

	static getAppointmentTechnicalFile(appointmentId, callback, callerror) {
		const url = `${urlInvoke}/appointment-schedules/${appointmentId}`;
		baseInvoker.get(url, callback, callerror);
	}

	static updateAppointmentMetadata(appointmentId, body, callback, callerror) {
		this.updateAppointmentTechnicalFile(appointmentId, body, callback, callerror);
	}

	static updateAppointmentTechnicalFile(appointmentId, body, callback, callerror) {
		const url = `${urlInvoke}/appointments/${appointmentId}/metadata`;
		baseInvoker.put(url, body, callback, callerror);
	}

	static getScheduleInCalendar(scheduleId, callback, callerror) {
		const url = `${urlInvoke}/schedule-in-calendar/${scheduleId}`;
		baseInvoker.get(url, callback, callerror);
	}

	static getScheduleBookingInCalendar(scheduleBookingId, callback, callerror) {
		const url = `${urlInvoke}/schedule-booking-in-calendar/${scheduleBookingId}`;
		baseInvoker.get(url, callback, callerror);
	}

	static getPackagesActive(callback, callerror) {
		const url = `${urlInvoke}/packages/?active=True`;
		baseInvoker.get(url, callback, callerror);
	}

	static getPackagesAll(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/packages/`, callback, callerror);
	}

	static getModulePractices(moduleId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/package/${moduleId}/practices`, callback, callerror);
	}

	static getPracticeTypesAll(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/practice_types`, callback, callerror);
	}

	static getPracticeTypes(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/practice_types?active=true`, callback, callerror);
	}
	static getOnlyCompanyPracticeTypesAll(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/practice_types`, callback, callerror);
	}

	static getGenericPracticeTypes(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/generic-practice-type`, callback, callerror);
	}


	static getCompanyPracticeTypes(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/company-practicetype`, callback, callerror);
	}

	static getCompanyPracticeType(practiceTypeId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/company-practicetype/${practiceTypeId}`, callback, callerror);
	}

	static getPracticeTypesGroup(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/practice-type-group`, callback, callerror);
	}

	static postCompanyPracticeType(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/company-practicetype`, body, callback, callerror);
	}
	
	static getCustomerSchedules(customerId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/customer/${customerId}/schedules`, callback, callerror);
	}

	static getScheduleStatuses(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/scheduleStatuses`, callback, callerror);
	}

	static getRepeatRecurrenceTypes(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/repeatRecurrenceTypes`, callback, callerror);
	}

	static getAppointmentTypes(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/appointmentTypes`, callback, callerror);
	}

	static getAppointmentTool(dateFrom, dateTo, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/tools-available?date-from=${dateFrom}&date-to=${dateTo}`, callback, callerror);
	}
	
	static getAppointmentSelectedTool( appointmentId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/appointments/${appointmentId}/tools`, callback, callerror);
	}

	static postAppointment(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/appointment`, body, callback, callerror);
	}

	static postAppointmentTool(appointmentid, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/appointments/${appointmentid}/tools`, body, callback, callerror);
	}

	static deleteAppointmentTool(appointmentId, toolId, callback, callerror) {
		baseInvoker.delete(`${urlInvoke}/appointments/${appointmentId}/tools/${toolId}`, callback, callerror);
	}

	static getAppointment(appointmentId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/appointment/${appointmentId}`, callback, callerror);
	}

	static cancelAppointment(appointmentId, body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/appointment/${appointmentId}/cancel-status`, body, callback, callerror);
	}

	static cancelAppointmentMobility(appointmentId, body, callback, callerror) {
		baseInvoker.put(`${urlInvokeMobility}/transferItemrequest/${appointmentId}/reject`, body, callback, callerror);
	}

	static cancelTransferAppointment(appointmentId, body, callback, callerror) {
		baseInvoker.put(`${urlInvokeMobility}/${appointmentId}/cancel`, body, callback, callerror);
	}
	static cancelPartialTransferAppointment( body, callback, callerror) {
		baseInvoker.post(`${urlInvokeMobility}/cancel-partitial`, body, callback, callerror);
	}

	static AcceptItemRequestMobility(appointmentItemRequestId, callback, callerror) {
		const url = `${urlInvokeMobility}/transferItemrequest/${appointmentItemRequestId}/accept`;
		baseInvoker.put(url,{}, callback, callerror);
	}

	static cancelSchedule(appointmentId, body, callback, callerror) {
		const url = `${urlInvoke}/appointment/${appointmentId}/schedules/cancel-status-with-motive`;
		baseInvoker.post(url, body, callback, callerror);
	}

	static getAppointmentCancellations(appointmentId, callback, callerror) {
		const url = `${urlInvoke}/appointment/${appointmentId}/cancel-status`;
		baseInvoker.get(url, callback, callerror)
	}

	static getTransferAppointmentCancellations(appointmentId, callback, callerror) {
		const url = `${urlInvoke}/${appointmentId}/cancel`;
		baseInvoker.get(url, callback, callerror)
	}

	static getAppointmentItemsPracticeModule(appointmentId, callback, callerror) {
		const url = `${urlInvoke}/appointment/items-request-practice-module/${appointmentId}`
		baseInvoker.get(url, callback, callerror);
	}

	static getRejectedReasons(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/reason-rejected`, callback, callerror);
	}

	static getAppointmentItems(appointmentId, callback, callerror) {
		const url = `${urlInvoke}/appointment/items-request/${appointmentId}`
		baseInvoker.get(url, callback, callerror);
	}
	

	static postAppointmentScheduleItems(appointmentId, body, callback, callerror) {
		const url = `${urlInvoke}/appointment/items-request/${appointmentId}`;
		baseInvoker.post(url, body, callback, callerror);
	}

	static postAcceptItemRequest(appointmentItemRequestId, callback, callerror) {
		const url = `${urlInvoke}/appointment/items-request/${appointmentItemRequestId}/accept`;
		baseInvoker.post(url,{}, callback, callerror);
	}

	static postRejectItemRequest(appointmentItemRequestId, reasonRejectedId, callback, callerror) {
		const body = { rejectedId: reasonRejectedId };
		const url = `${urlInvoke}/appointment/items-request/${appointmentItemRequestId}/reject`;
		baseInvoker.post(url, body, callback, callerror);
	}
	

	static deleteAppointmentScheduleItems(appointmentItemRequestId, callback, callerror) {
		const url = `${urlInvoke}/appointment/items-request/${appointmentItemRequestId}`;
		baseInvoker.delete(url, callback, callerror);
	}
	static getAppointmentItemsDetails(appointmentId, callback, callerror) {
		const url = `${urlInvoke}/appointment-detail/items-request/${appointmentId}`;
		baseInvoker.get(url, callback, callerror);
	}

	static getScheduleInformation(scheduleId, callback, callerror) {
		const url = `${urlInvoke}/schedule/${scheduleId}/information`;
		baseInvoker.get(url, callback, callerror);
	}

	static getScheduleResources(scheduleId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/schedule/${scheduleId}/resources`, callback, callerror);
	}

	static getCompanyPartnershipModules(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/partnership`, callback, callerror);
	}
	static getCompanyPartnershipAll(callback, callerror) {
		baseInvoker.get(`${urlInvoke}/partnership`, callback, callerror);
	}

	static getCompanyPartnership(practiceTypeId, callback, callerror) {
		baseInvoker.get(`${urlInvoke}/partnership/practice-type/${practiceTypeId}`, callback, callerror);
	}

	static newScheduleWithItems(transferId, itemIds, callback, callerror) {
		const itemIdsString = itemIds.join(',');
		const url = `${urlInvoke}/mobility/transfer/${transferId}/new-schedule/items/${itemIdsString}`;
		baseInvoker.post(url, {},callback, callerror);
	}
	
	  static confirmNewSchedule(transferId, callback, callerror) {
		baseInvoker.post(
		  `${urlInvoke}/mobility/transfer/${transferId}/confirm-schedule`,
		  callback,
		  callerror
		);
	  }
	  static saveBookings(bookingKey, callback, callerror) {
		console.log("ERROR:",callback)
		baseInvoker.post(
		  `${urlInvokeMobility}/transfer/save-bookings/${bookingKey}`,{},
		  callback,
		  callerror
		);
	  }
	
	  static cancelNewSchedule(transferId, callback, callerror) {
		baseInvoker.post(
		  `${urlInvoke}/mobility/transfer/${transferId}/cancel-schedule`,
		  callback,
		  callerror
		);
	  }


	static getHospital(callback, callerror) {
		let url = `${urlInvokeMobility}/hospital`;
		baseInvoker.get(url, callback, callerror);
	  }
	
	static getDrivers(callback, callerror) {
	let url = `${urlInvoke}/drivervehicle/all`;
	baseInvoker.get(url, callback, callerror);
	}
	
	static getQuantity(id, callback, callerror) {
	let url = `${urlInvokeMobility}/transfer/${id}/quantity`;
	baseInvoker.get(url, callback, callerror);
	}

	static getCompanyPartnershipMobility(callback, callerror) {
		let url = `${urlInvoke}/mobilityCompanyPartnership/get`;
		baseInvoker.get(url, callback, callerror);
	  }
	static getTransferAppointmentItems(appointmentId, callback, callerror) {
		const url = `${urlInvokeMobility}/transferItemRequest/${appointmentId}`
		baseInvoker.get(url, callback, callerror);
	}
	static postTransferAppointment(body, callback, callerror) {
		baseInvoker.post(`${urlInvokeMobility}/transfer/`, body, callback, callerror);
	}
	static postEditTransferAppointment(transferItemRequestId,body, callback, callerror) {
		baseInvoker.put(`${urlInvokeMobility}/transferItemRequest/${transferItemRequestId}/`, body, callback, callerror);
	}

	static postEditTransfer(transferId,body, callback, callerror) {
		baseInvoker.post(`${urlInvokeMobility}/transferItemRequest/${transferId}/`, body, callback, callerror);
	}
	
	static getSchedulesToReasing(params, callback, callerror) {
		const {
			startDate, endDate,
		} = params;
		const url = `${urlInvokeMobility}/schedules-to-reasign/?date-from=${startDate}&date-to=${endDate}`;
		baseInvoker.get(
			url,
			callback,
			callerror,
		);
	}

	static getDriverToReasing(id, callback, callerror) {
		baseInvoker.get(`${urlInvokeMobility}/driver-to-reasing/`, callback, callerror);
	}

	static scheduleReasignDriver(body, callback, callerror) {
		baseInvoker.post(`${urlInvokeMobility}/reasing`, body, callback, callerror);
	}

	static deleteTransferItem(transferItemRequestId,  callback, callerror) {
		baseInvoker.delete(`${urlInvokeMobility}/transferItemRequest/${transferItemRequestId}`,{}, callback, callerror);
	}

	static postSaveFile(body, callback, callerror) {
		baseInvoker.post(`${urlInvoke}/appointment/items-request/resources/save`, body, callback, callerror);
	}

	static getFile(appointmentItemRequestId, callback, callerror) {
		const url = `${urlInvoke}/appointment/items-request/resources/${appointmentItemRequestId}`
		baseInvoker.get(url, callback, callerror);
	}

	static putFile(appointmentItemRequestResourcesId,body, callback, callerror) {
		baseInvoker.put(`${urlInvoke}/appointment/items-request/resources/delete/${appointmentItemRequestResourcesId}/`, body, callback, callerror);
	}


}
